import { IBasicMarketplacePlanDto, IMarketplacePlanDto, Issuer } from 'api/generated/models';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

export const getPlansByCarrier = (
    marketplacePlans: IBasicMarketplacePlanDto[] | undefined,
    carrier: string | undefined
) =>
    orderBy(
        filter(marketplacePlans, (plan) => plan.issuer?.name === carrier),
        'name'
    );

export const getUniqueMarketplaceCarriers = (
    marketplacePlans: IBasicMarketplacePlanDto[] | IMarketplacePlanDto[] | undefined
) =>
    orderBy(
        uniqBy(marketplacePlans, 'issuer.name')
            .map((plan) => {
                if (plan?.issuer?.name !== undefined) {
                    plan.issuer.id = plan.issuer.name;
                    return plan.issuer;
                } else {
                    return undefined;
                }
            })
            .filter((x) => x !== undefined) as Issuer[],
        'name'
    );
