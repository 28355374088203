import { CLEAR_MARKETPLACE_PLANS } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { APPLY_SHOPPING_COVERAGE_CHANGES } from 'actions/householdShoppingCoverage/applyShoppingCoverageChanges';
import { GET_MARKETPLACE_PLAN_RATES_FOR_PLANS_ACTION } from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import { GET_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getMarketplacePlanRatesForUser';
import { GET_PLAN_DETAILS_FOR_BENEFITS_SELECTION_ACTION } from 'actions/marketplacePlan/getPlanDetailsForBenefitsSelection';
import { LIST_DETAILS_FOR_PLANS_FOR_HOUSEHOLD_ACTION } from 'actions/marketplacePlan/listDetailsForHousehold';
import { SEARCH_PLANS_ACTION } from 'actions/marketplacePlan/searchMarketplacePlans';
import { IMarketplacePlanDto } from 'api/generated/models';

const initialState: IMarketplacePlanDto[] = [];

export const marketplacePlans = (
    state = initialState,
    action: IAction<IMarketplacePlanDto[]> & { isRefresh?: boolean }
): IMarketplacePlanDto[] => {
    switch (action.type) {
        case SEARCH_PLANS_ACTION.failed:
        case LIST_DETAILS_FOR_PLANS_FOR_HOUSEHOLD_ACTION.failed:
        case APPLY_SHOPPING_COVERAGE_CHANGES:
        case CLEAR_MARKETPLACE_PLANS:
            return [];
        case GET_MARKETPLACE_PLAN_RATES_FOR_PLANS_ACTION.success:
        case GET_PLAN_DETAILS_FOR_BENEFITS_SELECTION_ACTION.success:
            if (action.isRefresh) {
                return state.map((plan) => {
                    const refreshedPlan = action.data.find((x) => x.id === plan.id);
                    return refreshedPlan ?? plan;
                });
            }
            return [...action.data];
        case SEARCH_PLANS_ACTION.success:
        case GET_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION.success:
        case LIST_DETAILS_FOR_PLANS_FOR_HOUSEHOLD_ACTION.success:
            return [...state, ...action.data];
        default:
            return state;
    }
};
