import { PathwayInputTypes, PathwayTypes, TeamStateIds } from 'api/generated/enums';
import { IPathway } from 'api/generated/models';
import some from 'lodash/some';
import { hasValue } from 'utilities';

export const getPathwayInput = (
    pathway: IPathway | undefined,
    pathwayInputType: PathwayInputTypes,
    defaultValue = ''
): string =>
    pathway?.pathwayInputs?.find((x) => x.pathwayInputTypeId === pathwayInputType)?.value ??
    defaultValue;

export const getIsPathwayOfType = (
    pathwayType: number | string | undefined,
    ...checkPathwayTypes: PathwayTypes[]
) => some(checkPathwayTypes, (x) => hasValue(pathwayType) && Number(pathwayType) === x);

export const isCappedWageUpPathwayType = (pathwayType: number | string) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.MarketplaceCappedEmployeeCost,
        PathwayTypes.MarketplaceCappedEmployerCost,
        PathwayTypes.HealthcareSharingCappedEmployeeCost,
        PathwayTypes.HealthcareSharingCappedEmployerCost
    );
export const isAnyWageUpPathwayType = (pathwayType: number | string | undefined) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.MarketplaceCappedEmployeeCost,
        PathwayTypes.MarketplaceCappedEmployerCost,
        PathwayTypes.HealthcareSharingCappedEmployeeCost,
        PathwayTypes.HealthcareSharingCappedEmployerCost,
        PathwayTypes.Custom,
        PathwayTypes.Spouse,
        PathwayTypes.Medicaid,
        PathwayTypes.Medicare,
        PathwayTypes.Parent,
        PathwayTypes.Flat
    );

export const isEmployeeCappedPathwayType = (pathwayType: number | string) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.HealthcareSharingCappedEmployeeCost,
        PathwayTypes.MarketplaceCappedEmployeeCost,
        PathwayTypes.IchraCappedEmployeeCost
    );

export const isEmployerCappedPathwayType = (pathwayType: number | string) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.HealthcareSharingCappedEmployerCost,
        PathwayTypes.MarketplaceCappedEmployerCost
    );

export const isExchangeBenchmarkPathwayType = (pathwayType: number | string) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.MarketplaceCappedEmployeeCost,
        PathwayTypes.MarketplaceCappedEmployerCost,
        PathwayTypes.IchraCappedEmployeeCost,
        PathwayTypes.IchraCappedEmployerCost,
        PathwayTypes.IchraAgeBanded
    );

export const isHealthcareSharingPathwayType = (pathwayType: number | string) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.HealthcareSharingCappedEmployeeCost,
        PathwayTypes.HealthcareSharingCappedEmployerCost
    );

const requiresCoverageLevelInputsForIchraPathwayType = (pathwayType: number | string) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.IchraCappedEmployeeCost,
        PathwayTypes.IchraCappedEmployerCost,
        PathwayTypes.IchraFlat,
        PathwayTypes.IchraMedicare
    );

export const isIchraPathwayType = (pathwayType: number | string | undefined) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.IchraCappedEmployeeCost,
        PathwayTypes.IchraCappedEmployerCost,
        PathwayTypes.IchraCustom,
        PathwayTypes.IchraFlat,
        PathwayTypes.IchraMedicare,
        PathwayTypes.IchraAgeBanded
    );

export const isCappedCostIchraPathwayType = (pathwayType: number | string) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.IchraCappedEmployeeCost,
        PathwayTypes.IchraCappedEmployerCost
    );

export const isIchraPathwayTypeWithPlanCost = (pathwayType: number | string | undefined) =>
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.IchraCappedEmployeeCost,
        PathwayTypes.IchraCappedEmployerCost,
        PathwayTypes.IchraMedicare,
        PathwayTypes.IchraAgeBanded
    );

export const isCustomReimbursementsAllowed = (pathwayType: number | string | undefined) =>
    !getIsPathwayOfType(pathwayType, PathwayTypes.IchraAgeBanded, PathwayTypes.IchraFlat);

export const requiresPlan = (pathwayType: number | string) =>
    isCappedWageUpPathwayType(pathwayType) ||
    isCappedCostIchraPathwayType(pathwayType) ||
    getIsPathwayOfType(pathwayType, PathwayTypes.IchraAgeBanded);

export const requiresCoverageLevelInputs = (pathwayType: number | string) =>
    isCappedWageUpPathwayType(pathwayType) ||
    requiresCoverageLevelInputsForIchraPathwayType(pathwayType) ||
    getIsPathwayOfType(
        pathwayType,
        PathwayTypes.Spouse,
        PathwayTypes.Parent,
        PathwayTypes.Flat,
        PathwayTypes.Medicare
    );

export const showActiveContributionByTeamState = (teamStateId: TeamStateIds) =>
    teamStateId !== TeamStateIds.Proposal && teamStateId !== TeamStateIds.Prospect;

export const getActiveAndProjectedDifferentMessage = (contributionType: string) =>
    `The Projected ${contributionType} was recalculated and needs to be set to Active`;
