import isEmpty from 'lodash/isEmpty';

type IApiActivity = Record<string, Record<string, boolean> | boolean>;

export const apiActivity = (
    state = {} as IApiActivity,
    action: { params: Record<string, string>; type: string }
): IApiActivity => {
    const { params, type } = action;
    const matches = /(.*)_(REQUESTED|SUCCESS|FAILED)/.exec(type);

    if (type === 'API_ACTIVITY_CLEAR') {
        return {};
    }

    // not a *_REQUESTED / *_SUCCESS /  *_FAILED actions, so we ignore them
    if (!matches) {
        return state;
    }

    const [, requestName, requestState] = matches;
    const newState = {
        ...state,
        // Store whether a request is happening at the moment or not
        // e.g. will be true when receiving GET_TODOS_REQUESTED
        //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILED
        [requestName as string]: requestState === 'REQUESTED',
    };
    if (!isEmpty(params)) {
        const key = new URLSearchParams(params);
        newState[requestName?.withParams as string] = {
            ...(state[requestName?.withParams as string] as Record<string, boolean>),
            [key.toString()]: requestState === 'REQUESTED',
        };
    }
    return newState;
};
